import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {  catchError, map, Observable, tap, throwError} from 'rxjs';
import {  ModelUpdateRecord, MultiRecord, SimpleStatus, SingleRecord, Status, VuModel } from 'src/app/hah-foundation/models/record.model';
import { HttpCoreService } from 'src/app/hah-foundation/services/http-core/http-core.service';

import { environment } from 'src/environments/environment';

import { Instructor,  Profile, ProfileAcademy, ProfileContact, Staff, Student } from '../../account/profile/models/profile.model';
import { Permission, Role, User } from '../../models/user.model';
import { SecPermission } from '../../setup/sec-permission/models/sec-permission.model';
import { SecRole } from '../../setup/sec-role/models/sec-role.model';
import { SecSubject } from '../../setup/sec-subject/models/sec-subject.model';

/*
*  User: Record used for authentication ex. firebase, login screen, contains uid/pwd
*  Profile: Record used in edudate-app full user information
*
*/



@Injectable({
  providedIn: 'root'
})
export class IAMAppService extends HttpCoreService {

  constructor(
    protected override httpAppClient: HttpClient
    )
  { super(httpAppClient); }


  private toProfile(response: SimpleStatus | SingleRecord | MultiRecord): Profile {
    let singleRecord: SingleRecord = this.toSingleRecord(response); //Alert if necessary
    return new Profile(singleRecord.model as Profile);
  }
  /*
    * Profile API
    */
  private profileApiUrl: string = `${environment.iamApiUrl}/user`;

  getProfileList(): Observable<MultiRecord> {
    return this.getList(this.profileApiUrl, Profile) //The list is casted to Profile[]
             .pipe(
              catchError((error)=> {
                return throwError(() => { return error })
              })
             )
  }
  getProfileById(profile_id: string): Observable<SingleRecord> {
    return this.getModelById(`${this.profileApiUrl}/${profile_id}`, Profile)
  }
  createProfile(profileRecord: Profile): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.createModel(this.profileApiUrl, profileRecord);
  }
  updateProfile(profile_update_record: ModelUpdateRecord): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.updateModel(this.profileApiUrl, profile_update_record);
  }
  deleteProfile(profile_id: string): Observable<SimpleStatus> {  //return should transform to Obervable<SimpleStatus>
    return this.deleteModel(`${this.profileApiUrl}/${profile_id}`);
  }
  /*
    * PersonaContact API
    */
  getPersonaContactListById(profile_id:string): Observable<MultiRecord> {
    return this.getList(this.profileApiUrl +`/${profile_id}/contact`, ProfileContact)
      .pipe(
        map((mr: MultiRecord) => mr)
      )
  }

  createPersonaContact(profile_id:string, personaContactRecord: ProfileContact): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.createModel(this.profileApiUrl +`/${profile_id}/contact`, personaContactRecord);
  }
  updatePersonaContact( personaContact_update_record: ModelUpdateRecord): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.updateModel(this.profileApiUrl, personaContact_update_record);
  }
  deletePersonaContact(profile_id: string,contact_id:string): Observable<SimpleStatus> {  //return should transform to Obervable<SimpleStatus>
    return this.deleteModel(this.profileApiUrl +`/${profile_id}/contact/${contact_id}`);
  }

  /*
    * PersonaAcademy API
    */
  getPersonaAcademyListById(profile_id:string): Observable<MultiRecord> {
    return this.getList(this.profileApiUrl +`/${profile_id}/academy`, ProfileAcademy)
      .pipe(
        map((mr: MultiRecord) => mr)
      )
  }
  createPersonaAcademy(profile_id:string, personaAcademyRecord: ProfileAcademy): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.createModel(this.profileApiUrl +`/${profile_id}/academy`, personaAcademyRecord);
  }
  updatePersonaAcademy( personaAcademy_update_record: ModelUpdateRecord): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.updateModel(this.profileApiUrl , personaAcademy_update_record);
  }
  deletePersonaAcademy(profile_id: string, academy_id: string): Observable<SimpleStatus> {  //return should transform to Obervable<SimpleStatus>
    return this.deleteModel(this.profileApiUrl +`/${profile_id}/academy/${academy_id}`);
  }
  /*
  * User API
  */
 private userApiUrl : string = `${environment.iamApiUrl}/user`
  login(user: User): Observable<SimpleStatus> {  // clear casting to SimpleStatus
    return this.post(`${this.userApiUrl}/login`, user)
      .pipe(
        map((response: SimpleStatus | SingleRecord | MultiRecord) => {
          let simpleStatus = this.toSimpleStatus(response);
          let status: Status = simpleStatus.status as Status;
          return simpleStatus;
        }));
  }


  private queryUser(query_key: string, query_value: string): Observable<User> { // clear casting to User; caller check user.id <> 0; alert the status
    return this.get(`${this.profileApiUrl}/query/${query_key}/${query_value}`)
      .pipe(
        map((response: SimpleStatus | SingleRecord | MultiRecord) => new User(this.toProfile(response))));
  }


  getUserByEmail(email: string): Observable<User> {
    return this.queryUser("email", email)
      .pipe(tap((user: User) => user));
  }


  getUserByUserName(name: string): Observable<User> {
    return this.queryUser("name", name)
      .pipe(map((user: User) => user))
  }

  getUserById(id: string): Observable<User> {
    return this.get(`${this.profileApiUrl}/${id}`)
      .pipe(
        map((response: SimpleStatus | SingleRecord | MultiRecord) => new User(this.toProfile(response))))
  }


  getUserByUid(uid: string): Observable<User> {
    return this.queryUser("uid", uid)
      .pipe(tap((user: User) => user));
  }

  verifyUniqueUserEmail(email: string): Observable<boolean> {
    return this.getUserByEmail(email)
      .pipe(map((user: User) => user == null))
  }

  /***************************************************************************************************************************************************** */
  // S E C U R I T Y
  // APIs to handle subjects and permissions of user
  //**************************************************************************************************************************************************** */private 
  updateSecurityByUser(user_id: string, userPermissions: VuModel): Observable<SimpleStatus> {
    return this.put(`${environment.iamApiUrl}/user/${user_id}/security`, userPermissions)
      .pipe(
        map((response: SimpleStatus | SingleRecord | MultiRecord) => this.toSimpleStatus(response))
      )
  }

  updateUserRoles(user_id: string, userRoles: VuModel): Observable<SimpleStatus> {
    return this.put(`${environment.iamApiUrl}/user/${user_id}/roles`, userRoles)
      .pipe(map((response: SimpleStatus | SingleRecord | MultiRecord) => this.toSimpleStatus(response)))
  }

  deleteUserRole(user_id: string, role_id: string): Observable<SimpleStatus> {
    return this.delete(`${environment.iamApiUrl}/user/${user_id}/role/${role_id}`)
      .pipe(map((response: SimpleStatus | SingleRecord | MultiRecord) => this.toSimpleStatus(response)))
  }

  updateUserPermissions(id: string, userPermissions: VuModel): Observable<SimpleStatus> {
    return this.put(`${environment.iamApiUrl}/user/${id}/permissions`, userPermissions)
      .pipe(map((response: SimpleStatus | SingleRecord | MultiRecord) => this.toSimpleStatus(response)))
  }

  deleteUserPermission(user_id: string, permission_id: string): Observable<SimpleStatus> {
    return this.delete(`${environment.iamApiUrl}/user/${user_id}/permission/${permission_id}`)
      .pipe(map((response: SimpleStatus | SingleRecord | MultiRecord) => this.toSimpleStatus(response)))
  }

  getRolesByUserId(user_id: string): Observable<Role[]> {
    return this.get(`${environment.iamApiUrl}/user/${user_id}/query/roles`)
      .pipe(
        map((response: SimpleStatus | SingleRecord | MultiRecord) => {
          let multiRecord: MultiRecord = this.toMultiRecord(response);
          let roles: Role[] = [];
          multiRecord.models?.forEach((m) => roles.push(new Role(m)))
          return roles;
        }));
  }
  getPermissionsByUserId(user_id: string): Observable<Permission[]> {
    return this.get(`${environment.iamApiUrl}/user/${user_id}/query/permissions`)
      .pipe(
        map((response: SimpleStatus | SingleRecord | MultiRecord) => {
          let multiRecord: MultiRecord = this.toMultiRecord(response);
          let permissions: Permission[] = [];
          multiRecord.models?.forEach((m) => permissions.push(new Permission(m)));
          // let status: Status = multiRecord.status as Status;
          // this.alert.info(status.message);
          return permissions;
        }));
  }


  getRoleList(): Observable<Role[]> {
    return this.get(`${environment.iamApiUrl}/role`)
      .pipe(map((response: SimpleStatus | SingleRecord | MultiRecord) => {
        let multiRecord: MultiRecord = this.toMultiRecord(response);
        let roles: Role[] = [];
        multiRecord.models?.forEach((m) => roles.push(new Role(m)))
        return roles;
      })
      )
  }

  getRoleById(id: string): Observable<Role> {
    return this.get(`${environment.iamApiUrl}/role/${id}`)
      .pipe(map((response: SimpleStatus | SingleRecord | MultiRecord) => {
        let singleRecord: SingleRecord = this.toSingleRecord(response);
        return new Role(singleRecord.model as Role);
      }));
  }

  deleteRole(id: string): Observable<SimpleStatus> {
    return this.delete(`${environment.iamApiUrl}/role/${id}`);
  }

  updateSecurityByRole(id: string, rolePermissions: VuModel): Observable<SimpleStatus> {
    return this.put(`${environment.iamApiUrl}/role/${id}/security`, rolePermissions);
  }

  getPermissionsByRoleId(role_id: string): Observable<Permission[]> {
    return this.get(`${environment.iamApiUrl}/role/${role_id}/query/permissions`)
      .pipe(
        map((response: SimpleStatus | SingleRecord | MultiRecord) => {
          let multiRecord: MultiRecord = this.toMultiRecord(response);
          let permissions: Permission[] = []; 
          multiRecord.models?.forEach((m) => permissions.push(new Permission(m)));
          // let status: Status = multiRecord.status as Status;
          // this.alert.info(status.message);
          return permissions;
        }));
  }


  /*
  * SecPermission API
  */
  private secPermissionApiUrl: string = `${environment.iamApiUrl}/sec-permission`;
  getSecPermissionList(): Observable<MultiRecord> {
    return this.getList(this.secPermissionApiUrl, SecPermission)
  }
  createSecPermission(secPermissionRecord: SecPermission): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.createModel(this.secPermissionApiUrl, secPermissionRecord);
  }
  updateSecPermission(secPermission_update_record: ModelUpdateRecord): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.updateModel(this.secPermissionApiUrl, secPermission_update_record);
  }
  deleteSecPermission(sec_permission_id: string): Observable<SimpleStatus> {  //return should transform to Obervable<SimpleStatus>
    return this.deleteModel(`${this.secPermissionApiUrl}/${sec_permission_id}`);
  }

  /*
    * SecSubject API
    */
  private secSubjectApiUrl: string = `${environment.iamApiUrl}/sec-subject`;
  getSecSubjectList(): Observable<MultiRecord> {
    return this.getList(this.secSubjectApiUrl, SecSubject)

  }
  createSecSubject(secSubjectRecord: SecSubject): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.createModel(this.secSubjectApiUrl, secSubjectRecord);
  }
  updateSecSubject(secSubject_update_record: ModelUpdateRecord): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.updateModel(this.secSubjectApiUrl, secSubject_update_record);
  }
  deleteSecSubject(sec_subject_id: string): Observable<SimpleStatus> {  //return should transform to Obervable<SimpleStatus>
    return this.deleteModel(`${this.secSubjectApiUrl}/${sec_subject_id}`);
  }

  /*
    * SecRole API
    */
  private secRoleApiUrl: string = `${environment.iamApiUrl}/sec-role`;
  getSecRoleList(): Observable<MultiRecord> {
    return this.getList(this.secRoleApiUrl, SecRole)

  }
  createSecRole(secRoleRecord: SecRole): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.createModel(this.secRoleApiUrl, secRoleRecord);
  }
  updateSecRole(secRole_update_record: ModelUpdateRecord): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.updateModel(this.secRoleApiUrl, secRole_update_record);
  }
  deleteSecRole(sec_role_id: string): Observable<SimpleStatus> {  //return should transform to Obervable<SimpleStatus>
    return this.deleteModel(`${this.secRoleApiUrl}/${sec_role_id}`);
  }

  

  /*
    * Instructor API
    */
  private instructorApiUrl: string = `${environment.iamApiUrl}/instructor`;
  getInstructorList(): Observable<Instructor[]> {
    return this.getList(this.instructorApiUrl, Instructor)
      .pipe(
        map((mr: MultiRecord) => mr.models as Instructor[])
      );
  }

  getInstructorById(instructor_id: string): Observable<SingleRecord> {
    return this.getModelById(`${this.instructorApiUrl}/${instructor_id}`, Instructor)
  }
  createInstructor(instructor_record: Instructor): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.createModel(this.instructorApiUrl, instructor_record);
  }
  updateInstructor( instructor_update_record: ModelUpdateRecord): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.updateModel(this.instructorApiUrl, instructor_update_record);
  }
  deleteInstructor(instructor_id: string): Observable<SimpleStatus> {  //return should transform to Obervable<SimpleStatus>
    return this.deleteModel(`${this.instructorApiUrl}/${instructor_id}`);
  }

  /*
    * Student API
    */
  private studentApiUrl: string = `${environment.iamApiUrl}/student`;
  getStudentList(): Observable<Student[]> {
    return this.getList(this.studentApiUrl, Student)
      .pipe(
        map((mr: MultiRecord) => mr.models as Student[])
      );
  }
  getStudentById(student_id: string): Observable<SingleRecord> {
    return this.getModelById(`${this.studentApiUrl}/${student_id}`, Student)

  }
  // 
  createStudent(student_record: Student): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.createModel(this.studentApiUrl, student_record);
  }
  updateStudent(student_update_record: ModelUpdateRecord): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.updateModel(this.studentApiUrl, student_update_record);
  }
  deleteStudent(student_id: string): Observable<SimpleStatus> {  //return should transform to Obervable<SimpleStatus>
    return this.deleteModel(`${this.studentApiUrl}/${student_id}`);
  }

  /*
    * Staff API
    */
  private staffApiUrl: string = `${environment.iamApiUrl}/staff`;
  getStaffList(): Observable<Staff[]> {
    return this.getList(this.staffApiUrl, Staff)
      .pipe(
        map((mr: MultiRecord) => mr.models as Staff[])
      );
  }


  getStaffById(staff_id: string): Observable<SingleRecord> {
    return this.getModelById(`${this.staffApiUrl}/${staff_id}`, Staff)

  }
  createStaff(staff_record: Staff): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.createModel(this.staffApiUrl, staff_record);
  }
  updateStaff(staff_update_record: ModelUpdateRecord): Observable<SimpleStatus> { //return should transform to Obervable<SimpleStatus>
    return this.updateModel(this.staffApiUrl, staff_update_record);
  }
  deleteStaff(staff_id: string): Observable<SimpleStatus> {  //return should transform to Obervable<SimpleStatus>
    return this.deleteModel(`${this.staffApiUrl}/${staff_id}`);
  }
}
