import { Observable } from "rxjs";
import { IModel, IVuModel, Model, SimpleStatus, VuModel } from "src/app/hah-foundation/models/record.model";



// export class Profile extends LookupModel {}
export interface IPersonaChildModel extends IModel {
    persona_statuses_code? :string;
}
export class Person 
extends Model 
implements IPersonaChildModel{
    persona_statuses_code? :string;
}

export class Profile extends Person {
 
//    id: number ;
    first_name  :string = '';
    middle_name :string = '';
    last_name   :string = '';
    birth_date?: Date;
    genders_code?: string ;

   constructor(init?:Partial<Profile>) {
       super(init);
       Object.assign(this, init);
   }
}
export class Instructor extends Person{
    rating?  :string;
    join_date? :Date;
}
export class Student extends Person{
}
export class Staff extends Person{
   
    hire_date? :Date;
    departments_id?: number;
  
   constructor(init?:Partial<Staff>) {
       super(init);
       Object.assign(this, init);
   }
}


export class ProfileContact extends Model{
    personas_id?: number ;
    contacts_id?: number;
    contact_title?: string;
    contact_info? :string;
    contact_typeclass_title?  :string;
    contact_types_id?   :number;
    contact_is_emergency?: boolean;
    contact_is_primary?:boolean;


   constructor(init?:Partial<ProfileContact>) {
       super(init);
       this.pkName="contacts_id";
   }
}

export class ProfileAcademy extends Model{
    personas_id?: number ;
    academies_id?: number;
    academy_title?  :string;
    academy_description?  :string;
    academy_short_code?  :string;
    is_account?: boolean;

   constructor(init?:Partial<ProfileAcademy>) {
       super(init);
       Object.assign(this, init);
       this.pkName="academies_id";
   }
}

export enum ProfileChild {
    MasterProfile = 0,
    Instructor = 1,
    Student = 2,
    Staff = 3,
    Lists = 4,
}

export enum ProfileAssociationList {
    Unknown = 0,
    Academies = 10,
    Contacts = 20,
}