import { CardHeader, IModel, Model, SimpleStatus, VuModel } from "src/app/hah-foundation/models/record.model";

import firebase from 'firebase/compat/app';
import { LOGINMETHOD } from "../services/auth/auth.service";
import { Observable } from "rxjs";


export class User extends Model {
    name?: string | null;
    email?: string | null;
    password?: string | null;
    displayName?: string | null;
    uid?: string;
    pictureUrl?: string | null;
    providerId?: string | null;
    isAnonymous?: boolean;
    phoneNumber: string | null = "";
    access_map?: Map<string, string[]>;
    updated_at?: string;
    created_at?: string;
    loginMethod?: LOGINMETHOD;


    updateFBUser(user: firebase.User | null) {
        if (user) {
            this.uid = user.uid;
            this.displayName = user.displayName;
            this.isAnonymous = user.isAnonymous;
            this.pictureUrl = user.photoURL;
            this.phoneNumber = user.phoneNumber;
            this.providerId = user.providerId;
        }
    }
}
// export interface UserId extends User { id: string; }


export class Guard  {
    subject: string ='';
    permission: string ='';
}

export class UserConfirmation extends Model{
    user?: User;
    message?: string;
}
export interface IRolePermission extends IModel {
    users_id: string;
    permissions_id: string;
    subjects_id: string;
}
export class RolePermission 
extends Model
implements IRolePermission {
    users_id: string = "";
    permissions_id: string = "";
    subjects_id: string = "";
}


export interface IRole {
    title: string;
    description: string;
}
export class Role 
extends Model
implements IRole   {
    title: string = "";
    description: string = "";
}
export interface IPermission {
    title: string;
    description: string;
    permissions_parent_id: number;
}
export class Permission 
extends Model
implements IPermission {
   
    title: string = "";
    description: string = "";
    permissions_parent_id: number = 0;
}

export interface IAppSubject {
    title: string;
    description: string;
    Subjects_parent_id: number;
}
export class AppSubject extends Model
implements IAppSubject {
    
    title: string = "";
    description: string = "";
    Subjects_parent_id: number = 0;

}